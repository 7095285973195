import { FC, useState } from 'react';

//routing
import { HashLink as Link } from 'react-router-hash-link';

// style
import { 
    Box
} from './style/LinkBox.style';


const LinkBox: FC<{to: string, title: string}> = ({ title, to }) => {

    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    
    const onMouseOverHandler = () => setIsMouseOver(true);
    const onMouseLeaveHandler = () => setIsMouseOver(false);

    return (
        <Box
            onMouseOver={onMouseOverHandler}
            onMouseLeave={onMouseLeaveHandler}
            isMouseOver={isMouseOver}
        >
            <Link to={to}>
                {title}
            </Link>
        </Box>
    );
};

export default LinkBox;
