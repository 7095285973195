import { FC, useState } from 'react';

//assets
import PhoneNotebook from './assets/phoneNotebook.png';

//style
import { SectionsBase } from '../style/Sections.style';
import {
    Section,
    TextBox,
    Title,
    BulletPointBox,
    BulletRow,
    BulletPoint,
    BulletText,
    ContactButton,
    ImageBox,
    NftImage

 } from './style/ReportTheCompany.style';


const ReportTheCompany: FC<{flavour?: 'ellenikaBlue'}> = ({ flavour }) => {

    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

    const onButtonClick = () => {
        window.location.href = '#contatti';
    };

    const onMouseOverHandler = () => setIsMouseOver(true);
    const onMouseLeaveHandler = () => setIsMouseOver(false);

    return (
        <SectionsBase flavour={flavour}>
            <Section>
                <ImageBox>
                    <NftImage alt='Phone image' src={PhoneNotebook} />
                </ImageBox>

                <TextBox>
                    <Title>
                        Segnalaci la tua azienda preferita
                    </Title>

                    <BulletPointBox>
                        <BulletRow>
                            <BulletPoint />

                            <BulletText>
                                Il programma di NFT unico "Ellen Pass" offre un'opportunità eccezionale 
                                per le aziende partner di promuovere i loro prodotti o servizi senza 
                                sostenere costi pubblicitari aggiuntivi o pagare commissioni per 
                                l'acquisizione di nuovi clienti.
                            </BulletText>
                        </BulletRow>

                        <BulletRow>
                            <BulletPoint />

                            <BulletText>
                                Ellen Pass, disponibile in tre diverse rarità, fornisce benefici 
                                esclusivi ai possessori, incentivando gli utenti ad aderire al 
                                programma e a sostenere le aziende partner. Attraverso un processo di 
                                verifica accurato, il programma assicura che solo gli utenti 
                                verificati possano accedere ai benefici e garantire che le 
                                aziende partner possano riservare sconti speciali agli utenti aventi diritto.
                            </BulletText>
                        </BulletRow>

                        <BulletRow>
                            <BulletPoint />

                            <BulletText>
                                Questo programma rappresenta una soluzione innovativa per il 
                                coinvolgimento delle aziende e degli utenti all'interno della 
                                piattaforma Ellenika Utility e dei suoi partner.
                            </BulletText>
                        </BulletRow>

                        <BulletRow>
                            <BulletPoint />

                            <BulletText>
                                Segnalaci la tua azienda preferita e portala nel mondo del Web3 e degli NFT 
                                passando dalla porta principale
                            </BulletText>
                        </BulletRow>

                        <BulletRow>
                            <BulletPoint />

                            <BulletText>
                                Presentaci la tua azienda preferita e, oltre ad avere la possibilità di acquisire 
                                uno dei fantastici NFT che l'azienda rilascerà, sarai premiato anche da noi
                                con un regalo UNICO
                            </BulletText>
                        </BulletRow>
                    </BulletPointBox>

                    <ContactButton 
                        onClick={onButtonClick}
                        onMouseOver={onMouseOverHandler}
                        isMouseOver={isMouseOver}
                        onMouseLeave={onMouseLeaveHandler}
                    >
                        Entra in contatto con noi
                    </ContactButton>
                </TextBox>
            </Section>
        </SectionsBase>
    );
};

export default ReportTheCompany;
