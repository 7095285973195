import styled, { css } from 'styled-components';
import { GlobalContactButton } from 'components/buttons';
import devices from 'style/breakpoints/breakpoints';

export const Section = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column-reverse;
    gap: 20px;
    
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;

    @media ${devices.tablet} {

        flex-direction: row;
    }
`;

export const TextBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 600px;
`;

export const Title = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 30px;
    text-align: left;

    @media ${devices.tablet} {
        font-size: 40px;
    }

`;

export const BulletPointBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    margin: 20px 0 0;
`;

export const BulletRow = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const BulletTextArea = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
`;

export const BulletTitle = styled.h4`

    margin: 0;
    padding: 0;

    font-size: 24px;
    font-weight: 600;
`;

export const BulletPoint = styled.div`

    width: 20px;
    height: 20px;

    margin: 10px 20px 0 0;

    border-radius: 50%;
    background: white;

    flex-shrink: 0;
`;

export const BulletText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 20px;
    text-align: left;
`;

export const ContactButton = styled(GlobalContactButton)<{isMouseOver: boolean}>`

    margin: 50px 0 0;

    ${({isMouseOver}) => isMouseOver && (
        
        css`

            color: #1E2251FC;
            background: white;
        `
    )}
`;

export const ImageBox = styled.div`
    width: 100%;
`;

export const NftImage = styled.img`
    width: 100%;
`;
