import { FC } from 'react';

//assets
import EllenikaLogo from 'assets/logo-orizzontale.png';
import DiscordLogo from './assets/discord-logo.jpg';
import InstagramLogo from './assets/instagram-logo.png';
import OpenSeaLogo from './assets/opensea-logo.png';
import TwitterLogo from './assets/twitter-logo.png';

//style
import { 
    FooterRow,
    Content,
    LogoBox,
    InformationContainer,
    InfoBox,
    InfoTitle,
    InfoDescription,
    LinkBox,
    LinkText,
    SocialContainer,
    SocialBox
} from './style/Footer.style';


const Footer: FC<{}> = () => {

    const openMailHandler = () => window.open(
        'mailto:info@ellenikautility.com',
        '_blank',
        'noopener noreferrer'
    );

    return (
        <FooterRow>
            <Content>
                <LogoBox>
                    <img alt='Ellenika logo' src={EllenikaLogo} />
                </LogoBox>

                <InformationContainer>
                    <InfoBox>
                        <InfoTitle>
                            Ellenika Utility Srl
                        </InfoTitle>

                        <InfoDescription>
                            Via Daniele Piccinini, 2 - 24122 Bergamo (BG)
                        </InfoDescription>

                        <InfoTitle>
                            Capitale Sociale Interamente Versato
                        </InfoTitle>

                        <InfoDescription>
                            10.000 €
                        </InfoDescription>
                    </InfoBox>

                    <InfoBox>
                        <InfoTitle>
                            P.IVA - COD.FISC - REG.IMPR
                        </InfoTitle>

                        <InfoDescription>
                            04660680168 
                        </InfoDescription>

                        <InfoTitle>
                            REA 
                        </InfoTitle>

                        <InfoDescription>
                            BG – 479563 
                        </InfoDescription>
                    </InfoBox>

                    <InfoBox>
                        <InfoTitle>
                            @mail
                        </InfoTitle>

                        <InfoDescription 
                            onClick={openMailHandler}
                            isPointer
                        >
                            info@ellenikautility.com
                        </InfoDescription>
                    </InfoBox>

                    <LinkBox>
                        <LinkText href='https://drive.google.com/file/d/1d6UewyUKty9VX8BbhvJeg6IUVJKfv68I/view?usp=drive_link'>
                            Privacy
                        </LinkText>

                        <LinkText href='https://drive.google.com/file/d/1PTXn-NEp9z8ZT_Hnnz07QxCoMAB6T9Ve/view?usp=drive_link'>
                            Cookie
                        </LinkText>
                    </LinkBox>
                </InformationContainer>

                <SocialContainer>
                    <SocialBox href='https://discord.gg/Gn5qpDdqwF'>
                        <img alt='Discord logo' src={DiscordLogo} />
                    </SocialBox>
                    
                    <SocialBox href='https://twitter.com/Ellenika_uNFT'>
                        <img alt='Twitter logo' src={TwitterLogo} />
                    </SocialBox>

                    <SocialBox href='https://www.instagram.com/ellenika_utility/'>
                        <img alt='Instagram logo' src={InstagramLogo} />
                    </SocialBox>

                    <SocialBox href='https://opensea.io/ELLENIKA_UTILITY'>
                        <img alt='OpenSea logo' src={OpenSeaLogo} />
                    </SocialBox>
                </SocialContainer>
            </Content>
        </FooterRow>
    );
};

export default Footer;
