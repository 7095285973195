import { GlobalContactButton } from 'components/buttons';
import devices from 'style/breakpoints';
import styled, { css } from 'styled-components';

export const Section = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    /* gap: 20px; */

    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
`;

export const Title = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 30px;
    text-align: left;

    @media ${devices.tablet} {

        font-size: 40px;
    }
`;

export const ContactText = styled.p`

    margin: 20px 0 0;
    padding: 0;
    
    font-size: 20px;
`;

export const SendMailButton = styled(GlobalContactButton)<{isMouseOver: boolean}>`

    margin: 50px 0 0;
    border-color: #1E2251FC;
    color: #1E2251FC;

    ${({isMouseOver}) => isMouseOver && (
        
        css`

                color: white;
                background: #1E2251FC;
        `
    )}
`;

export const ContactForm = styled.form`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 40px;

    max-width: 1200px;
    padding: 20px 40px;

    border: 5px solid #df03e8;
    border-radius: 15px;
`;

export const NameInput = styled.input`

    width: 100%;
    margin: 0 0 0 10px;
`;

export const MailInput = styled.input`

    width: 100%;
    margin: 0 0 0 10px;
`;

export const InputLabel = styled.label`

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 100%;
`;

export const SubmitButton = styled(GlobalContactButton)`

    align-self: center;
`;

export const CheckBoxContainer = styled.div`

    max-width: 500px;
`;

export const CheckBoxRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CheckBox = styled.input`

    cursor: pointer;
`;

export const CheckBoxLabel = styled.p`

    margin: 0 0 0 10px;
    padding: 0;

    font-size: 16px;

    a{

        text-decoration: none;
        color: black;

        cursor: pointer;
    }
`;

export const CheckBoxInfo = styled.p`

    margin: 40px 0 0;
    padding: 0;

    font-size: 12px;
    font-style: italic;
`;