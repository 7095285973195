import { FC, useState } from 'react';

//assets
import EllenPass from './assets/ellenpass.png';

//style
import { SectionsBase } from '../style/Sections.style';
import {
    Section,
    TextBox,
    Title,
    BulletPointBox,
    BulletRow,
    BulletTextArea,
    BulletTitle,
    BulletPoint,
    BulletText,
    ContactButton,
    ImageBox,
    NftImage
 } from './style/CompaniesContact.style';


const CompaniesContact: FC<{flavour?: 'ellenikaBlue'}> = ({ flavour }) => {

    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

    const onButtonClick = () => {
        window.location.href = 'https://app.ellenikautility.com';
    };

    const onMouseOverHandler = () => setIsMouseOver(true);
    const onMouseLeaveHandler = () => setIsMouseOver(false);

    return (
        <SectionsBase flavour={flavour} id='cosafacciamo' >
            <Section>
                <TextBox>
                    <Title>
                        Benvenuti nel mondo di Ellenika Utility, il provider utility club basato su Web3 che
                        mette l'utente al centro di tutto.
                    </Title>

                    <BulletPointBox>
                        <BulletRow>
                            <BulletPoint />

                            <BulletTextArea>
                                <BulletTitle>
                                    Cos'è Ellenika Utility?
                                </BulletTitle>

                                <BulletText>
                                    Semplice: è la tua chiave per accedere a una serie di 
                                    vantaggi esclusivi a lungo termine.
                                </BulletText>
                            </BulletTextArea>
                        </BulletRow>

                        <BulletRow>
                            <BulletPoint />

                            <BulletTextArea>
                                <BulletTitle>
                                    Come posso ottere questi vantaggi esclusivi?
                                </BulletTitle>

                                <BulletText>
                                    Per ottenere questi vantaggi ti basterà 
                                    diventare Titolare di EllenPass, ovvero acquistare il nostro NFT unico.
                                    Così facendo ti si aprirà un mondo di opportunità e
                                    potrai godere di una vasta gamma di promozioni esclusive fornite da aziende di valore.
                                </BulletText>
                            </BulletTextArea>
                        </BulletRow>

                        <BulletRow>
                            <BulletTextArea>
                                <BulletText>
                                    Immagina di poter ottenere sconti e offerte speciali lifetime per le tue spese
                                    quotidiane e per realizzare i tuoi progetti personali. 
                                    Con Ellen Pass tutto ciò è possibile!
                                </BulletText>
                            </BulletTextArea>
                        </BulletRow>

                        <BulletRow>
                            <BulletTextArea>
                                <BulletText>
                                    NB: <i>Ellenpass NFT sarà lanciato ufficialmente nel Q3 2023. Seguiranno info e news specifiche, vi
                                    consigliamo di seguire la pagina ufficiale di Twitter</i>
                                </BulletText>
                            </BulletTextArea> 
                        </BulletRow>

                        <BulletRow>
                            <BulletPoint />

                            <BulletTextArea>
                                <BulletText>
                                    Ellen Pass sarà disponibile in tre diverse rarità: Premium, Gold e Platinum. Ogni rarità
                                    offrirà benefici differenti in base al valore del NFT pass acquistato (Es: l'nft platinum
                                    avrà un % maggiore di sconto da poter applicare rispetto al Gold o al Premium).
                                    Ellen Pass è stato ideato per dar vita a un ecosistema completo di utility ed esperienze
                                    di qualsiasi genere che si adattano alle tue esigenze e passioni.
                                </BulletText>
                            </BulletTextArea>
                        </BulletRow>

                    </BulletPointBox>

                    <ContactButton 
                        onClick={onButtonClick}
                        onMouseOver={onMouseOverHandler}
                        isMouseOver={isMouseOver}
                        onMouseLeave={onMouseLeaveHandler}
                    >
                        Accedi alla web app
                    </ContactButton>
                </TextBox>

                <ImageBox>
                    <NftImage alt='Phone image' src={EllenPass} />
                </ImageBox>
            </Section>
        </SectionsBase>
    )
};

export default CompaniesContact;