import devices from 'style/breakpoints/breakpoints';
import styled, { css } from 'styled-components';

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    
    width: 100%;

    position: relative;

    a {
        color: yellow;
        ::before ::after {
            color: white;
        }
        text-decoration: none;
    }
`;

export const AbsoluteImageBox = styled.div<{isReturnToTopArrow?: boolean, isMouseOver?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;

    margin: 0 0 0 80vw;

    background: white;
    border-radius: 50%;
    border: 5px solid #1E2251FC;
    position: fixed;
    bottom: 5vh;

    ${({isReturnToTopArrow}) => isReturnToTopArrow && (

        css`
            bottom: 15vh;
        `
    )}

    ${({isMouseOver}) => isMouseOver && (

        css`

            background-color: #df03e8;
        `
    )}

    @media ${devices.tablet} {

        margin: 0 0 0 90vw;
    }
`;

export const ReturnToTopImage = styled.img`

    width: 30px;

    background: transparent;


    cursor: pointer;
`;

export const CookieLockImage = styled.img`

    width: 30px;

    background: transparent;


    cursor: pointer;
`;
