import { FC, useState } from 'react';

// style
import { 
    Box,
    Text
} from './style/LinkText.style';


const LinkText: FC<{href: string, title: string, isDownloadable?: boolean}> = ({ title, href, isDownloadable }) => {

    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    
    const onMouseOverHandler = () => setIsMouseOver(true);
    const onMouseLeaveHandler = () => setIsMouseOver(false);

    return (
        <Box
            onMouseOver={onMouseOverHandler}
            onMouseLeave={onMouseLeaveHandler}
            isMouseOver={isMouseOver}
        >
            {!isDownloadable && (
                <Text href={href}>
                    {title}
                </Text>
            )}
            
            {isDownloadable && (
                <Text 
                    href={href}
                    download={title}
                    target='_blank'
                >
                    {title}
                </Text>
            )}
        </Box>
    );
};

export default LinkText;
